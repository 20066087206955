@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.h1 {
  font-size: 48px;
  line-height: 56px;
}

.h2 {
  font-size: 32px;
  line-height: 49px;
}

.h3 {
  font-size: 24px;
  line-height: 30px;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.text {
  &.s {
    font-size: 14px;
    line-height: 20px;
  }

  &.m {
    font-size: 16px;
    line-height: 22px;
  }

  &.l {
    font-size: 18px;
    line-height: 24px;
  }
}

.default {
  color: var(--typography-default-color);
}

.secondary {
  color: var(--typography-secondary-color);;
}

.error {
  color: var(--typography-error-color);;;
}

.lightWeight {
  font-weight: 300;
}
.mediumWeight {
  font-weight: 400;
}
.mediumHeightWeight {
  font-weight: 500;
}

.semiBoldWeight {
  font-weight: 600;
}

.boldWeight {
  font-weight: 700;
}

.noWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}