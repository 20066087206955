// Main
  $colorGreenGradient: linear-gradient(90deg, #291AED 0%, #C59AFC 100%);
  $colorGreenAcid: #8A7FF6;
  $colorGreen700: #E9D9FE;

  // Dark
  $colorBlackBG: #111215;
  $colorBlack700: #191A1D;
  $colorBlack600: #1E2023;
  $colorBlack500: #25272E;

  // Light
  $colorGrey700: #64656A;
  $colorGrey600: #C4C4C4;
  $colorGrey500: #F2F2F2;
  $colorGrey400: #F9FAFB;
  $colorGrey300: rgba(255,255,255, 0.3);

// Primitive
  $colorBlue: #6FB0FE;
  $colorYellow: #F3CE5D;
  $colorRed: #FF4040;
  $colorGreen: #6CDC13;
  $colorWhite: #FFFFFF;

// Complex
  $colorDarkBlue: #2E3A59; 
  $colorMediumBlue: #6C778F;
  $colorGlacierWhite: #DFE3EC;
  $colorPlatinumGrey: #C5CEE0;
  $colorUniqueGrey: #C9C9C9;
  $colorLightPurplishBlue: #958BF6;
  $colorOnyx: #17181C;
  $colorMediumSlate: #7A8499;
  $colorBlueValley: #6f778b;
  $colorFrenchBlue: #8F9BB3;
  $colorCharcoalGrey: #28292C;
  $colorMoonstone: #F4F7FE;
  $colorTenebre: #353638;
  $colorOrionGrey: #55565B;
  $colorTricornBlack: #2F3033;
  $colorEbonyBlack: #040405;
  $colorSomberGrey: #737478;
  $colorOlympicWhite: #EEEEEE;
  $colorOxfordBlue: #192038;
  $colorPitchBlack: #2B2B2B;
  $colorSavoyGray: #495057;
  $colorYellowMediumLightShade: #F2C94C;
  $colorJasmineYellow: #FDC740;
  $colorBrightGrey: #FEFEFE;
  $colorLightGrey: #E1E5EE;
  $colorBerryHead: #A60E49;
  $colorGullGrey: #5A5956;
  $colorDupont: #1F1F1F;
  $colorWhite50: rgba(0,0,0,.5);
  $colorWhite25: rgba(0, 0, 0, 0.25);
  $colorGradientPurple: linear-gradient(90deg, #BF8FFB 0%, #877EF6 100%);
  $colorGradientGreenToBlue: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
  $colorGradientGreyToWhite: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  $colorGradientPinkToYellow: linear-gradient(to right, #fa709a 0%, #fee140 100%);
  $colorGradientRedToOrange: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
  $colorGradientPinkToBlue: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
  $colorGreenShadow: rgba(203, 206, 0, 0.28);


//// new colors
$colorGray15: #37373A;
$colorGray20: #434345;
$colorGray40: #4B5563;
$colorGray60: #9CA3B0;
$colorGray80: #262629;

$colorGrayIcon: #7D7D7F;

$colorWhite: #FFFFFF;
$colorGreen200: #70FF00;


$colorGreenGradient700: linear-gradient(180deg, #E1E365 0%, #CBCE00 100%);
$colorGreenGradient600: linear-gradient(180deg, #ABF6A5 0%, #72FC66 100%);
$colorGreen600: #E1E365;
$colorGreen500:  #8DFA84;

// dark 

$colorBlack400: #141417;
$colorBlack300: rgba(255, 255, 255, 0.08);
$colorBlack200: rgba(52, 47, 27, 0.2);
$colorBlack100: rgba(255, 255, 255, 0.06);
$colorBlack50: rgba(255, 255, 255, 0.02);


$colorWhite20: rgba(255, 255, 255, 0.2);
$colorWhite15: rgba(255, 255, 255, 0.15);

