@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.stakingpool { 
    background-color: var(--card-default-background);
    margin: spacing(6, 4) !important;
    padding: spacing(4, 2.75, 2.5, 2.75);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
}

.stakingpoolInfos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
  gap: spacing(1.5);
  padding: spacing(2);
}

.buttonGroupWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
  gap: spacing(1.5);
  padding: spacing(2);
}

.buttonGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(94px, 1fr));
  gap: spacing(1.5);
}

.button {
  min-width: 150px;
  margin: auto !important;
}

.modalButton {
  min-width: 100px;
  margin: auto !important;
  margin-top: 20px !important;
}

.logoImg {
  width: 75px;
}

.inputs {
  & input {
    border-color: white !important;
    margin-top: 20px !important;
  }
}

@include forDesktopMiddle {
  .stakingpoolInfos {
    display: grid;
    grid-template-columns: none;
    gap: spacing(1.5);
    padding-bottom: spacing(3);
  }
}

.subtitle {
  font-size: 16px;
  line-height: initial;
}

.titleGroup {
  display: flex;
  justify-content: center;
  & > .title {
    align-self: center;
    margin-left: 20px;
  }
}

.infoGroup {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;

  h3 {
    text-align: center;
  }
  & > .leftInfo {
    padding-right: 10px;
    border-right: 1px solid #777;
  }
  
  & > .middleInfo {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  & > .rightInfo {
    padding-left: 10px;
    border-left: 1px solid #777;
  }
}

@include forDesktopSm {
  .buttonGroupWrapper {
    display: grid;
    grid-template-columns: none;
    gap: spacing(1.5);
    padding-bottom: spacing(3);
  }

  .button {
    min-width: 120px;
    padding: 0 !important;
    margin: auto !important;
  }

  .infoGroup {
    display: block;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    & > .leftInfo {
      padding: 0px;
      border-right: none;
      text-align: center;
    }
    
    & > .middleInfo {
      padding: 0px;
      border-right: none;
      text-align: center;
    }
    
    & > .rightInfo {
      padding: 0px;
      text-align: center;
    }
  }
}