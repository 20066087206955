@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


.nftCard {
    min-width: 380px; 
    padding: spacing(2.5);
    border-radius: 24px;
    border: 1px solid var(--card-default-border-green);
    @include flexbox;
    flex-direction: column;
    object-fit: cover;
    & .imgCard {
        width: 100%;
        height: 350px; 
    }
}
.title {
    padding: spacing(2);
}
.viewButton {
    margin-bottom: spacing(2) !important;
}
@include forTablet {
    .nftCard {
        min-width: 162px;
        min-height: 223px;
        border-radius: 10px;
        padding: spacing(1);
    }
    .title {
        padding: spacing(0.75);
        font-size: 16px;
        line-height: 20px;
    }
    .viewButton {
        margin: 0 auto;
        margin-bottom: spacing(1.5) !important;
        padding: spacing(0.5,5) !important
    }
}