@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.smainWrapper {
    max-width: 659px;
}
.mainTitle {
    padding-bottom: spacing(0.75);
}
.title {
    margin-bottom: spacing(3) !important;
}
.crowdsale { 
    background-color: var(--card-default-background);
    padding: spacing(3, 2.75, 5, 2.75);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
}
.progressBar {
    margin-top: spacing(1) !important;
    margin-bottom: spacing(3) !important;
}

.purchaseAmount {
    @include flexbox(space-between);
    margin-bottom: spacing(3) !important;

    & > .purschAmount {
        color:var(--typography-green-color);
    }
    & > .purschAmount:first-child {
        min-width: 130px;
    }
}
@include forTablet {
    .purchaseAmount > p {
        max-width: 120px;
    }
    .textUnderInput p:first-child {
        max-width:150px
    }
}
.textUnderInput {
    @include flexbox(space-between);
    margin-top: spacing(1) !important;
    margin-bottom: spacing(3) !important;
}
.buyInfo {
    background-color: #171717;
    padding: spacing(1.5, 3);
    margin-top: spacing(1.5) !important;
    border-radius: 8px;
    margin-bottom: spacing(3) !important;
}
.buyButton {
    align-self: center;
}
.wrapInputWithSelect {
    position: relative;
    @include flexbox(flex-end)
}
.inputs {
    position: absolute;
}

.selects {
    max-width: 30%;
    background-color: var(--input-default-background);
}
.Ticketbtn {
    z-index: 1;
    margin-right: spacing(0.5) !important;
    font-size: 25px !important;
    line-height: 25px !important;
}

.referral {
    width: 100%;
}