@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.followUs { 
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(343px,1fr));
    gap: spacing(3);
    content-visibility: auto;
}
.title {
    padding-bottom: spacing(5.75);
}
.testBackground {
    background-color: black;
}

.voteup {
    display: flex;
    justify-content: center;
    margin-top: 50px !important;
    margin-bottom: -25px !important;
}

.iframe {
    border: none;
    height: 70px;
    width: 290px;
    border-radius: 8px;
}