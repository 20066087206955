@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.selectCurrency {
  min-width: fit-content;
}

.control {
  background: $colorBlack400 !important;
  border-radius: 8px !important;
  border: 1px solid $colorWhite20 !important;
  & > div.valueContainer {
    & > .singleValue {
      color: $colorWhite !important;
      padding-top: 0;
      padding-left: 8px;
      font-size: 25px;
      line-height: 25px;
      display: flex !important;
      align-items: center !important;

    }
    & > div.placeholder {
      padding-top: 12px;
      display: none;
    }
  }
  &:hover, &:focus, &:active, &.open {
    background: $colorBlack400 !important;

    border: 1px solid $colorWhite20 !important;
  }
  & > div.indicatorsContainer > svg > g > path {
      fill: $colorWhite !important;
    }
}
.option {
  background: $colorBlack400 !important;
  display: flex !important;
  align-items: center !important;
  padding: 16px !important;
  &:hover {
    background: $colorWhite15 !important;
  }
}

.optionText {
  color: $colorWhite !important;
  font-size: 25px !important;
  line-height: 25px !important;
}

.menuList, .menu {
  scrollbar-width: none;
  background: $colorBlack400 !important;
  border-radius: 8px !important;
  border: 1px solid $colorWhite20 !important;
  &::-webkit-scrollbar {
    display: none;
  }
  &:hover, &:focus, &:active, &.open {
    border: 1px solid $colorWhite20 !important;
  }
}


