@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.lotteryWrapper {
  overflow-y: scroll !important;
  width: 100vw;
  min-height: 100vh;
  & > .breadcrumbs {
    display: none;
  }
  & > .container{
    padding-top: 120px;
  }
}

.bannerImg {
  max-width: 450px;
}

.bannerImg:hover {
  animation: pulse 2s infinite;
}

.bannerGroup {
  display: flex;
  justify-content: center;
  margin-top: 15px !important;
  margin-bottom: 10px;

  .infoGroup {
    align-self: center;
    margin-left: 50px;

    h3 {
      display: flex;
      line-height: 3rem;

      .money {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
  }
}

.actionGroup {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px !important;
}

.rewardInfo {
  display: flex;
  align-self: center;
}

@include forDesktopMiddle {
  .bannerGroup {
    display: grid;
    .infoGroup {
      margin-left: 0;
    }
  }
}

@include forDesktopSm {
  .actionGroup {
    display: grid;
    justify-items: center;
    button {
      margin-top: 10px !important;
    }
  }
}

.round {
  margin-top: 50px !important;
}

.podium {
  justify-content: center;
}

@include forDesktopSm {
  .lotteryWrapper {
    & > .breadcrumbs {
      display:unset;
      position: absolute;
      top: 10vh;
    }
    & > .container {
      height: 100%;
      & > .title {
        display: none;
      }
    }
  }
}

.responsiveTable {
  display: block;
  width: 90%;
  margin: auto !important;
  text-align: center;
  li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .tableHeader {
    background-color: #95A5A6;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .tableRow {
    font-size: 16px;
    background-color: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.1);
  }
  .col1 {
    flex-basis: 20%;
  }
  .col2 {
    flex-basis: 40%;
    overflow-wrap: anywhere;
  }
  .col3 {
    flex-basis: 40%;
  }
  .col4 {
    flex-basis: 80%;
  }
  // .col3 {
  //   flex-basis: 30%;
  //   display: flex;
  //   justify-content: center;
  //   font-size: 22px;
  // }
  
  @media all and (max-width: 767px) {
    width: 90%;
    .tableHeader {
      display: none;
    }
    .tableRow{
      
    }
    li {
      display: block;
    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
}

.modalButton {
  min-width: 100px;
  margin: auto !important;
  margin-top: 20px !important;
  padding: 10px !important;
}

.shareButton {
  display: flex !important;
  margin: auto !important;
  color: white !important;
  font-size: 20px !important;
}

.shareGroup {
  display: flex;
  justify-content: space-evenly;
}

@media all and (max-width: 767px) {
  .infoGroup {
    h3 {
      font-size: 20px !important;
    }
  }
}
