@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';


.header {
  position: relative;
  @include flexbox(space-between,center);
  padding: spacing(3, 6);
  position: absolute;
  left: 0; right: 0;
  width: 100%;
  transition: 0ms !important;
}

.headerNav {
  transition: 0ms !important;
  &:hover {
    text-decoration: underline;
    text-decoration-color: $colorGreen600;
    text-underline-offset: 8px;
  }
}

.coin {
  height: 48px;
  width: 48px;
}

.buyButton {
  max-width: 200px;
}

@include forDesktopSm{
  .header{
    padding: spacing(3, 4);
  }
}

@include forTablet{
  .header{
    padding: spacing(3);
  }
}