@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.breadcrumb, .breadcrumbFirst, .breadcrumbLast {
	list-style-type:none;
	display: inline list-item;
	color: var(--breadcrumbs-icon-default-color);
	padding: 0;
	margin-left: 8px;
	& > a {
		color: $colorWhite !important;
	}
}
.breadcrumbLast {
	list-style-type:none;
	& > a {
		color: $colorGreen600 !important;
	}
}
.breadcrumbFirst {
  list-style-type: none;
	margin: 0;
}

ul {
	display: flex;
	padding: 0;
	& > .container {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 9px;
	}
}
