@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.loader {
    z-index: 999;
    height: 100%;
    background-color: transparent;
    @include flexbox();

  .icon {
    width: 100px;
    height: auto;
    z-index: 999;
    position: relative;
    top: -5px;
    @include rotateAnimation;
  }
}