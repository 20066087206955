@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';
@import '/src/assets/styles/component-variables/color-constants';

* {
  transition: 0ms !important;
}

.modalOutside {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 997;
  &.backgroundColor {
    background: $colorWhite50;
  }
}

.modal {
  position: fixed;
  display: block;
  padding-top: spacing(6);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
  bottom: 0;
  width: 100%;
  border: 1px solid $colorGreen600 !important;
  border-radius: 8px !important;
  background-color: var(--card-default-background-modal) !important;
  z-index: 998;
}

.modalContent {
  padding: spacing(3, 0, 0, 0);
}

.modalHeader {
  width: 100%;
  height: fit-content;
  @include flexbox(space-between, center); 
  padding-bottom: spacing(2);
}

.divider {
  border-bottom: 1px solid $colorGreen600;
  margin-bottom: spacing(2) !important;
}

.closeButton {
  transform: translateX(6px);
}

.closed {
  visibility: hidden;
  z-index: -99999;
}
