@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.banner {
  background: url('../../assets/img/icons/BannerBackgroundImg.png') no-repeat ;
  background-size: cover;
  // height: 100vh;
  overflow: hidden;
  padding: 150px 100px;
  @include flexbox;
  & > .container {
    overflow: hidden;
    max-width: 65%;
    height: 100%;
    @include flexbox;
    gap: 71px;
    & > .bannerText {
      overflow: hidden;
      max-height: 80%;
    }
  }
}

@media screen and (max-width: 750px) {
  .banner{
    padding: 150px 10px;
    & > .container{
      max-width: 85%;
      .bannerText {
        font-size: 14px;
      }
    }
    &Text {
      font-size: 22px;
    }
  }
}