@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(0.95);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.founderAds {
  width: 80%;
  margin-left: 10%;
}

.founderAds:hover {
  animation: pulse 2s infinite;
}

.stakingWrapper {
  overflow-y: scroll !important;
  width: 100vw;
  min-height: 100vh;
  & > .breadcrumbs {
    display: none;
  }
  & > .container{
    padding-top: 120px;
    
    & > .bodyText {
      font-size: 128px;
      line-height: 192px;
      background: $colorGreenGradient700;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform:uppercase;
    }
  }
}

@include forDesktopSm {
  .stakingWrapper {
    & > .breadcrumbs {
      display:unset;
      position: absolute;
      top: 10vh;
    }
    & > .container {
      height: 100%;
      & > .title {
        display: none;
      }
      & > .bodyText {
        font-size: 60px;
        line-height: inherit;
      }
      & > .returnButton {
        position: absolute;
        bottom: 5vh;
      }
    }
  }
}

@include forTablet{
  .stakingWrapper{
    .breadcrumbs{
      padding-left: 16px;
    }
  }
}