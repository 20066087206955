@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.wrapContainer { 
    max-width: 1344px;
    margin: 0 auto !important;
    padding: spacing(10,6);
    
}
@include forTablet {
    .wrapContainer {
        padding: spacing(3);
    }
}