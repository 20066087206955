@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.mobileButtonWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
}

.menuButton {
  color: $colorGreen600 !important;
  &:hover {
    color: $colorBlack400 !important;
  } 
}
.menuModal {
  position: absolute;
  top:210px
}
