@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.wrapper {
  background: var(--card-default-background);
  border: 1px solid var(--card-default-border);
  display: inline-block;
  padding: spacing(3);
  border-radius: var(--card-default-border-radius);
  margin: 0 auto;
  max-width: 350px;
  min-height: 224px;
}

.sm {
  min-width: 350px;
  min-height: 224px;
}

.md {
  min-width: 420px;
  min-height: 300px;
}

.lg {
  min-width: 500px;
  min-height: 350px;
}

.none {
  min-height: 0;
  min-width: 0;
  width: fit-content;
  height: fit-content;
}

.hoverEffect {
  &:hover {
    border: 1px solid var(--card-default-hover);
  }
}


@include forTablet{
  .lg,
  .md,
  .sm{
    min-width: 100%;
  }
}