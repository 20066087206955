@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.root {
  @include flexbox();
  flex-direction: column;
  & > img {
    border: 2px solid #E1E365;
    border-radius: 24px;
    margin-bottom: 16px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .avatar{
    max-width: 100%;
  }
  .socials {
    margin-top: 4px;
    @include flexbox(center, center);
    .social{
      color: #E1E365;
      padding: 0 5px;
      transition: all 400ms ease-in-out !important;
      &.active{
        cursor: pointer;
        &:hover{
          transform: translateY(-4px);
        }
      }
    }
  }
}

