@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.button {
  width: fit-content;
  cursor: pointer;
  border-radius: 8px;
  @include flexbox();
  transition: 0ms !important;
  
  &.outlined {
    background: var(--button-outlined-default-background);
    border: $button-border-width solid var(--button-outlined-default-border);
    color: var(--button-outlined-default-color);

    .startAdorment > svg > path,
    .endAdorment > svg > path {
      fill: red;
    }

    &:hover {
      background: var(--button-outlined-hover-background);
      border: $button-border-width solid var(--button-outlined-hover-border);
      color: var(--button-outlined-hover-color);
      background-origin: border-box;

      .startAdorment > svg > path,
      .endAdorment > svg > path {
        fill: var(--button-outlined-hover-icon-color);
      }
    }

    &:active {
      background: var(--button-outlined-active-background);
      border: $button-border-width solid var(--button-outlined-active-border);
      color: var(--button-outlined-active-color);
      background-origin: border-box;

      .startAdorment > svg > path,
      .endAdorment > svg > path {
        fill: var(--button-outlined-active-icon-color);
      }
    }
  }

  &.filled {
    background: var(--button-filled-default-background);
    border: $button-border-width solid var(--button-filled-default-border);
    color: var(--button-filled-default-color);
    background-origin: border-box;
    box-shadow: 0px 8px 16px var(--button-shadow-color);

    .startAdorment > svg > path,
    .endAdorment > svg > path {
      fill: var(--button-filled-default-icon-color);
    }

    &:hover {
      background: var(--button-filled-hover-background);
      border: $button-border-width solid var(--button-filled-hover-border);
      color: var(--button-filled-hover-color);
      background-origin: border-box;

      .startAdorment > svg > path,
      .endAdorment > svg > path {
        fill: var(--button-filled-hover-icon-color);
      }
    }

    &:active {
      background: var(--button-filled-active-background);
      border: $button-border-width solid var(--button-filled-active-border);
      color: var(--button-filled-active-color);
      background-origin: border-box;

      .startAdorment > svg > path,
      .endAdorment > svg > path {
        fill: var(--button-filled-active-icon-color);
      }
    }
  }
  &.filled-secondary {
    border: $filled-secondary-button-border-width solid var(--filled-secondary-button-secondary-default-border);
    color: var(--filled-secondary-button-secondary-default-font);
    background: var(--filled-secondary-button-secondary-default-background);
    .endAdorment > svg > path,
    .startAdorment > svg > path {
      fill: var(--filled-secondary-button-secondary-default-icon);
      transition: ease-in-out 200ms;
    }
    &:hover {
      color: var(--filled-secondary-button-secondary-hover-font);
      border: $filled-secondary-button-border-width solid var(--filled-secondary-button-secondary-hover-border);
      .endAdorment > svg > path,
      .startAdorment > svg > path {
        fill: var(--filled-secondary-button-secondary-hover-icon);
      }
    }
    &:active {
      color: var(--filled-secondary-button-secondary-active-font);
      border: $filled-secondary-button-border-width solid var(--filled-secondary-button-secondary-active-border);
      .endAdorment > svg > path,
      .startAdorment > svg > path {
        fill: var(--filled-secondary-button-secondary-active-icon);
      }
    }
    &.disabled {
      color: var(--filled-secondary-button-secondary-default-font);
      background: var(--filled-secondary-button-secondary-default-background);
      border: none;

      &:hover{
        color: var(--filled-secondary-button-secondary-default-font);
        background: var(--filled-secondary-button-secondary-default-background);
        border: none;
        .endAdorment > svg > path,
        .startAdorment > svg > path {
        fill: var(--filled-secondary-button-secondary-default-icon);
        }   
      }
      
      &:active {
        color: var(--filled-secondary-button-secondary-default-font);
        background: var(--filled-secondary-button-secondary-default-background);
        border: none;
        .endAdorment > svg > path,
        .startAdorment > svg > path {
          background: var(--filled-secondary-button-secondary-default-background);
          border: none;
  
          & > svg > path {
            fill: var(--filled-secondary-button-secondary-default-icon);
          }
        }
      }
    
  }
  }
  &.text {
    background: transparent !important;
    color: var(--typography-default-color);
    border: none;
    padding: 0 !important;
    margin: 0;

    &:hover:not(.disabled) {
      filter: brightness(80%);
    }
  }

  &.disabled {
    background: var(--button-disabled-background);
    color: var(--button-disabled-color);
    border: none;
    cursor: not-allowed;

    .startAdorment > svg > path,
    .endAdorment > svg > path {
      fill: var(--button-outlined-disabled-icon-color);
    }

    &:hover {
      background: var(--button-disabled-background);
      color: var(--button-disabled-color);
      border: none;

      .startAdorment > svg > path,
      .endAdorment > svg > path {
        fill: var(--button-outlined-disabled-icon-color);
      }
    }

    &:active {
      background: var(--button-disabled-background);
      color: var(--button-disabled-color);
      border: none;

      .startAdorment > svg > path,
      .endAdorment > svg > path {
        fill: var(--button-outlined-disabled-icon-color);
      }
    }
  }

  .startAdorment {
    display: flex;
    margin-right: spacing(1);
  }

  .endAdorment {
    display: flex;
    margin-left: spacing(4);
  }

  &.lg {
    padding: spacing(2, 4);
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    &.endAdormentPadding {
      padding: spacing(2, 2.5, 2, 3);
    }
    &.startAdormentPadding {
      padding: spacing(2, 3, 2, 2);
    }
    &.bothAdormentsPadding {
      padding: spacing(2);
    }
  }

  &.md {
    padding: spacing(1, 7);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    &.endAdormentPadding {
      padding: spacing(2, 2, 2, 3);
    }
    &.startAdormentPadding {
      padding: spacing(2, 3, 2, 2);
    }
    &.bothAdormentsPadding {
      padding: spacing(2);
    }
  }

  &.sm {
    padding: spacing(1, 2);
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;

    &.endAdormentPadding {
      padding: spacing(1, 1, 1, 1.5);
    }
    &.startAdormentPadding {
      padding: spacing(1, 2.5, 1, 2);
      font-size: 20px;
    }
    &.bothAdormentsPadding {
      padding: spacing(1);
    }
  }
}

.icon {
  width: fit-content;
  cursor: pointer;
  border-radius: 8px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center;
  align-items: center;

  &.noSize {
    width: fit-content;
    height: fit-content;
    padding: 0;
  }

  &.outlined {
    background: var(--icon-button-outlined-default-background);
    border: $button-border-width solid var(--icon-button-outlined-default-border);

    & > svg > path {
      fill: var(--icon-button-outlined-default-icon-color);
    }

    &:hover {
      background: var(--icon-button-outlined-hover-background);
      border: $button-border-width solid var(--icon-button-outlined-hover-border);

      & > svg > path {
        fill: var(--icon-button-outlined-hover-icon-color);
      }
    }

    &:active {
      background: var(--icon-button-outlined-active-background);
      border: $button-border-width solid var(--icon-button-outlined-active-border);

      & > svg > path {
        fill: var(--icon-button-outlined-active-icon-color);
      }
    }

    &.disabled {
      background: var(--icon-button-outlined-default-background);
      border: $button-border-width solid var(--icon-button-outlined-default-border);

      & > svg > path {
        fill: var(--icon-button-outlined-secondary-disabled-icon-color);
      }
      
      &:hover {
        background: var(--icon-button-outlined-hover-background);
        border: $button-border-width solid var(--icon-button-outlined-hover-border);

        & > svg > path {
          fill: var(--icon-button-outlined-secondary-disabled-icon-color);
        }
      }
  
      &:active {
        background: var(--icon-button-outlined-active-background);
        border: $button-border-width solid var(--icon-button-outlined-active-border);

        & > svg > path {
          fill: var(--icon-button-outlined-disabled-icon-color);
        }
      }
    }
  }

  &.filled {
    background: var(--icon-button-filled-default-background);
    border: $button-border-width solid var(--icon-button-filled-default-border);
    color: var(--icon-button-filled-default-icon-color);

    &:hover {
      background: var(--icon-button-filled-hover-background);
      border: $button-border-width solid var(--icon-button-filled-hover-border);
      color: var(--icon-button-filled-hover-icon-color);

    }

    &:active {
      background: var(--icon-button-filled-active-background);
      border: $button-border-width solid var(--icon-button-filled-active-border);
      color:  var(--icon-button-filled-active-icon-color);
    }

    &.disabled {
      background: var(--icon-button-filled-disabled-background);
      border: none;
      color: var(--icon-button-filled-disabled-icon-color);
      
      &:hover {
        background: var(--icon-button-filled-disabled-background);
        border: none;
      }
  
      &:active {
        background: var(--icon-button-filled-disabled-background);
        border: $button-border-width solid var(--icon-button-filled-disabled-border);
      }
    }
  }

  &.filled-secondary {
    border: $filled-secondary-button-border-width solid var(--icon-button-filled-secondary-default-border);
    background: var(--icon-button-filled-secondary-default-background);
    color: var(--icon-button-filled-secondary-default-icon-color);
    
    &:hover {
      border: $filled-secondary-button-border-width solid var(--icon-button-filled-secondary-hover-border);
      background: var(--icon-button-filled-secondary-hover-background);
      color: var(--icon-button-filled-secondary-hover-icon-color) ;
    }
    &:active {
      border: $filled-secondary-button-border-width solid var(--icon-button-filled-secondary-active-border);
      background: var(--icon-button-filled-secondary-active-background);
      color: var(--icon-button-filled-secondary-active-icon-color);
    }
    &.disabled {
      background: var(--icon-button-filled-secondary-disabled-background);
      border: none;

      color: var(--icon-button-filled-secondary-disabled-icon-color);
      
      &:hover {
        background: var(--filled-secondary-button-secondary-default-background);
        border: none;

        color: var(--icon-button-filled-secondary-default-icon-color);
      }
  
      &:active {
        background: var(--filled-secondary-button-secondary-default-background);
        border: none;

        color: var(--icon-button-filled-secondary-default-icon-color);
      }
  }
}
  &.outlined-secondary {
    background: var(--icon-button-outlined-secondary-default-background);
    border: $button-border-width solid var(--icon-button-outlined-secondary-default-border);

    & > svg > path {
      fill: var(--icon-button-outlined-secondary-default-icon-color);
    }

    &:hover {
      background: var(--icon-button-outlined-secondary-hover-background);
      border: $button-border-width solid var(--icon-button-outlined-secondary-hover-border);

      & > svg > path {
        fill: var(--icon-button-outlined-secondary-hover-icon-color);
      }
    }

    &:active {
      background: var(--button-outlined-secondary-active-background);
      border: $button-border-width solid var(--button-outlined-secondary-active-border);

      & > svg > path {
        fill: var(--icon-button-outlined-secondary-active-icon-color);
      }
    }

    &.disabled {
      background: var(--icon-button-outlined-secondary-disabled-background);
      border: $button-border-width solid var(--icon-button-outlined-secondary-disabled-border);

      & > svg > path {
        fill: var(--icon-button-outlined-secondary-disabled-icon-color);
      }
      
      &:hover {
        background: none;
        border: none;

        & > svg > path {
          fill: var(--icon-button-outlined-secondary-disabled-icon-color);
        }
      }
  
      &:active {
        background: var(--icon-button-outlined-secondary-disabled-background);
        border: $button-border-width solid var(--icon-button-outlined-secondary-disabled-border);

        & > svg > path {
          fill: var(--icon-button-outlined-secondary-disabled-icon-color);
        }
      }
    }
  }

  &.disabled {
    border: none;
    cursor: not-allowed;
  }
}
