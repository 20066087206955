html,
body,
div[id='root'] {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border: 0;
  font-family: "Poppins";
  background: $colorBlack400;

  div,
  input,
  optgroup,
  select,
  a,
  button,
  textarea {
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    transition: 200ms;
  }
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  p {
    margin: 0;
    // transition: $transition-time;
  }

  ul,
  ol {
    margin: 0;
  }

  a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
  }
  img {
    // transition: $transition-time;
  }

  button {
    box-sizing: border-box;
  }

  .flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// elrond dapp ui
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// toastify styles
.Toastify__toast-container {
  width: fit-content;
}
.Toastify__toast {
  width: 100%;
  background: transparent;
  box-shadow: none;
}

.rc-slider * {
  transition: 100ms !important;
}

.rc-slider-rail {
  background: var(--slider-default-rail);
}
.rc-slider-track {
  background: var(--slider-default-track);
}

.rc-slider-handle {
  width: 25px;
  height: 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background: var(--slider-default-handle);
  border: none;
  top: -6px;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.rc-slider-handle-click-focused:focus {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.rc-slider-handle:hover {
}
.rc-slider-handle:active {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}