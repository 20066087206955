@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.stakingWrapper {
  overflow-y: scroll !important;
  width: 100vw;
  min-height: 100vh;
  & > .breadcrumbs {
    display: none;
  }
  & > .container{
    padding-top: 120px;
    
    & > .bodyText {
      font-size: 128px;
      line-height: 192px;
      background: $colorGreenGradient700;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-transform:uppercase;
    }
  }
}

.infoGroup {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  & > .leftInfo {
    padding-right: 10px;
    border-right: 1px solid #777;
  }
  
  & > .middleInfo {
    padding-left: 10px;
    border-right: 1px solid #777;
    padding-right: 10px;
    display: flex;
  }
  
  & > .rightInfo {
    padding-left: 10px;
    display: flex;
  }
}

@include forDesktopSm {
  .stakingWrapper {
    & > .breadcrumbs {
      display:unset;
      position: absolute;
      top: 10vh;
    }
    & > .container {
      height: 100%;
      & > .title {
        display: none;
      }
      & > .bodyText {
        font-size: 60px;
        line-height: inherit;
      }
      & > .returnButton {
        position: absolute;
        bottom: 5vh;
      }
    }
  }

  .infoGroup {
    display: block;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
    & > .leftInfo {
      padding: 0px;
      border-right: none;
      text-align: center;
    }
    
    & > .middleInfo {
      padding: 0px;
      border-right: none;
      text-align: center;
      justify-content: center;
    }
    
    & > .rightInfo {
      padding: 0px;
      text-align: center;
      justify-content: center;
    }
  }
}