@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.Logo { 
  @include flexbox(space-between);
  width: fit-content;
  & > svg {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 18px;
  } 
}
.logoImg {
  width: 75px;
  margin-right: spacing(2);
}
.textContainer {
  @include flexbox();
  flex-direction: column;
}
.textForTablet {
  display: none;
}

@include forTablet {
  .Logo {
    display: block;
    text-align: center;
    & > svg {
      margin: auto
    }
  }
  .textForTablet {
    display: block;
    padding-top: spacing(3);
  }
  .noneContent {
    display: none
  }  
}
