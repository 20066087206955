@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.followCard {  
  @include flexbox(align-items);
  background-color: var(--card-default-background);
  border-radius: spacing(3);
  padding: spacing(2.5, 1, 2, 5.25);
  color: var(--typography-default-color);
  position: relative;
  z-index: -2;
  width: 100%;
  svg {
    height: 40px;
    width: 40px;
  }
}
.name {
  padding-left: spacing(2);
}