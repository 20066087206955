@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';


.notFound { 
  @include flexbox(center);
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  & > .logo {
    position: absolute;
    top: 5vh;
  }
  & > .container {
    height: 60%;
    @include flexbox(space-between);
    flex-direction: column;
    .headerText {
      @include flexbox(center);
      flex-direction: column;
      & > .errorCode {
        font-size: 288px;
        line-height: inherit;
        background: $colorGreenGradient700;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      & > .textBody {
        margin-top: revert;
        width: 70%;
      }
      
    }
    & > .returnButton {
      margin-top: 20px;
    }
    
  }
}
@include forDesktopSm {
  .notFound {
    @include flexbox(space-between);
    & > .logo {
      & > div {
        display: none;
      }
      & > svg {
        width: 48px;
        height: 48px;
        margin: 0;
      }
    }
    & > .container {
      height: 100%;
      @include flexbox(space-evenly);
      & > .headerText {
        & > .errorCode {
          font-size: 128px;
          line-height: 192px;
        }
        & > .errorText {
          font-size: 18px;
        }
        & > .textBody {
          font-size: 14px;
        }
      }
      & > .returnButton {
        position: absolute;
        bottom: 5vh;
      }
    }
  }
}