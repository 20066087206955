@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.footer {
  width: 100%;
  @include flexbox(space-between,flex-end);
  border-top: 1px solid var(--dividerColorDefault);
  padding-top: spacing(7);
  padding-bottom: spacing(6);
  transition: 0ms !important;
}
.linksWrapper {
  @include flexbox(space-between);
  min-width: 320px;
}
@include forTablet {
  .footer {
    @include flexbox;
    padding-top: spacing(4);
  }
  .linksWrapper {
    display:none
  }
}
@include forDesktopSm {
  .linksWrapper {
    flex-direction: column;
    min-width: auto;
  }
}

