@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.mainTitle {
    padding-bottom: spacing(5.75);
    width: 100%;
}
.wrapperCard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
}
.cardWrapper{
    padding: 10px;
    flex: 0 0 33%;
    @include forDesktopMiddle{
        flex: 0 0 50%;
    }
    @include forTablet{
        flex: 0 0 100%;
        width: 100%;
    }
}
.cardItem {
    background-color: var(--card-default-background);
    border-radius: 24px;
    max-width: 700px;
    padding: spacing(4.25,6.5);
    height: 100%;

    & .title {
        padding-bottom: 20px;
    }
    & .description{
        padding-right: 10px;
        max-width: 280px;
    }
}


@include forTablet{
    .cardItem{
        width: 100%;
        padding: spacing(2);
    }
}
